import { graphql, navigate } from "gatsby"
import _ from "lodash"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

import { ProductCard } from "../components/Card/types"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import SectionTitle from "../components/SectionTitle"
import {
  createStaticContent,
  formatStaticContent,
  getPageTitle,
  transformToArray,
} from "../data/transform"

export const query = graphql`
  query ProductsPageQuery {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    products: allAirtable(filter: { table: { eq: "Products" } }) {
      ...ProductsFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_products: allAirtable(
      filter: {
        table: { eq: "StaticContent" }
        data: { page: { eq: "products" } }
      }
    ) {
      ...StaticContentFragment
    }
  }
`

const ProductsPage = props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  const products = transformToArray(props.data, "products")
  const content = createStaticContent(props.data, "products")

  return (
    <PageLayout locations={locations} static_content={content}>
      <Helmet>
        <title>{getPageTitle(content)}</title>
      </Helmet>
      <PageContentsWrapper>
        <SectionTitle
          title={formatStaticContent(content, "products-title")}
          desc={formatStaticContent(content, "products-desc")}
          bg_icon="lineart3"
        />
        <ItemRows child_limit={0}>
          {_.map(products, (prod, i) => {
            return (
              <ProductCard
                key={i}
                title={`${prod.name}`}
                linkAddress={`/products/${prod.product}`}
                desc={prod.longDescription}
                icon={_.get(prod, "icon.0.url")}
              />
            )
          })}
        </ItemRows>
      </PageContentsWrapper>
    </PageLayout>
  )
}

export default ProductsPage
